<template>
  <div class="login-form">
    <p class="login-form__title">Tell us who you are!</p>

    <v-form @submit.prevent="signInUserAnonymously" ref="form">
      <p>Email</p>
      <v-text-field
        type="text"
        required
        :rules="[rules.required, rules.email]"
        placeholder="Email"
        v-model="email"
      ></v-text-field>

      <div class="two-col-form">
        <div class="two-col-input">
          <p>First name</p>
          <v-text-field
            type="text"
            required
            :rules="[rules.required]"
            placeholder="Jane"
            v-model="firstName"
          ></v-text-field>
        </div>

        <div class="two-col-input">
          <p>Last name</p>
          <v-text-field
            type="text"
            required
            :rules="[rules.required]"
            placeholder="Smith"
            v-model="lastName"
          ></v-text-field>
        </div>
      </div>

      <div class="submit-wrap flex d-flex">
        <input type="submit" :disabled="!isFormValid" value="Log in" />
      </div>

      <div class="terms-and-conditions mt-2">
        By logging in you agree to our
        <a @click.stop :href="privacyLink" target="_blank">Terms & Conditions</a
        >.
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { Navigation } from "@/helpers"
import HubSpot from "@/services/hubspot.service"
import LoginMixin from "@/components/User/LoginAndOnboarding/mixins/LoginMixin"

const PRIVACY_LINK = "https://www.thegogame.com/team-building/privacy-policy/"

export default {
  name: "EmailCSVForm",
  props: ["id", "client"],
  mixins: [LoginMixin],
  data() {
    return {
      email: "",
      firstName: "",
      lastName: "",
      privacyLink: PRIVACY_LINK,
      isFormValid: false,
      rules: {
        required: value => !!value || "Field is required",
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Invalid e-mail."
        }
      }
    }
  },

  computed: {
    identifier() {
      return this.customInput
        ? this.customInput
        : this.customInputType &&
          this.customInputOptions &&
          this.customInputOptions.length
        ? // default to the last item on the list
          this.customInputOptions[this.customInputOptions.length - 1]
        : null
    },

    formContent() {
      return `${this.email}|${this.firstName}|${this.lastName}`
    }
  },

  watch: {
    formContent(val, prevVal) {
      if (val !== prevVal) {
        if (this.$refs.form.validate()) {
          this.isFormValid = true
        } else {
          this.isFormValid = false
        }
      }
    }
  },
  methods: {
    ...mapActions("auth", ["signUserInWithEmail"]),
    async signInUserAnonymously() {
      HubSpot.push([
        "trackCustomBehavioralEvent",
        {
          name: "pe2803895_sign_in",
          properties: {
            account_type: "anon-with-email"
          }
        }
      ])

      this.$_LoginMixin_maybeTriggerHubspotDemoEvent("anon-with-email")

      HubSpot.track()

      const parsed = Navigation.parseUrlID(this.id)

      await this.signUserInWithEmail({
        firstname: this.firstName,
        lastname: this.lastName,
        email: `${parsed?.clientID}.${this.email}`.toLowerCase(),
        identifier: this.identifier,
        orgID: this.client.orgID,
        audit: this.$_LoginMixin_audit,
        password: null,
        ...parsed
      })
    }
  }
}
</script>
