var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c("p", { staticClass: "login-form__title" }, [
        _vm._v("Tell us who you are!"),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.signInUserAnonymously.apply(null, arguments)
            },
          },
        },
        [
          _c("p", [_vm._v("Email")]),
          _c("v-text-field", {
            attrs: {
              type: "text",
              required: "",
              rules: [_vm.rules.required, _vm.rules.email],
              placeholder: "Email",
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _c("div", { staticClass: "two-col-form" }, [
            _c(
              "div",
              { staticClass: "two-col-input" },
              [
                _c("p", [_vm._v("First name")]),
                _c("v-text-field", {
                  attrs: {
                    type: "text",
                    required: "",
                    rules: [_vm.rules.required],
                    placeholder: "Jane",
                  },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "two-col-input" },
              [
                _c("p", [_vm._v("Last name")]),
                _c("v-text-field", {
                  attrs: {
                    type: "text",
                    required: "",
                    rules: [_vm.rules.required],
                    placeholder: "Smith",
                  },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "submit-wrap flex d-flex" }, [
            _c("input", {
              attrs: {
                type: "submit",
                disabled: !_vm.isFormValid,
                value: "Log in",
              },
            }),
          ]),
          _c("div", { staticClass: "terms-and-conditions mt-2" }, [
            _vm._v(" By logging in you agree to our "),
            _c(
              "a",
              {
                attrs: { href: _vm.privacyLink, target: "_blank" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [_vm._v("Terms & Conditions")]
            ),
            _vm._v(". "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }